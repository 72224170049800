import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { Head, Link } from '@inertiajs/inertia-vue3'
import vueCountryRegionSelect from 'vue3-ts-country-region-select'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { ZiggyVue } from '@/../vendor/tightenco/ziggy/dist/vue.es.js';

import "@/scss/app.scss";
import "@/css/app.css";
import "@/css/tailwind.css";


library.add(faAngleDown);
library.add(faBell);
library.add(faQuestionCircle);

createInertiaApp({
    //resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    title: title => `${title} | Vinyl Siding Institute`,
    resolve: (name) => resolvePageComponent(`../views/pages/${name}.vue`, import.meta.glob('../views/pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        createApp({ render: () => h(app, props) })
            .use(plugin)
            .use(vueCountryRegionSelect)
            .use(ZiggyVue)
            .component('InertiaHead', Head)
            .component('InertiaLink', Link)
            .component('font-awesome-icon', FontAwesomeIcon)
            .mount(el)
    },
})
